import { memo } from 'react';

interface ElementMatchInfoProps {
  match: ElementMatchInfoData;
}

interface ElementMatchInfoData {
  home_team_name: string;
  away_team_name: string;
  home_team_score: number;
  away_team_score: number;
  competition_name: string;
  season: string;
  date: string;
}

const ElementMatchInfo = ({ match }: ElementMatchInfoProps) => {
  const date = new Date(match.date);

  return (
    <>
      <div className="flex flex-wrap items-center gap-2">
        <span className="text-xs font-semibold">
          {match.home_team_name} - {match.away_team_name} ({match.home_team_score}:{match.away_team_score})
        </span>
        <div className="h-3.5 w-px bg-gray-300" />
        <span className="text-xs font-semibold">{match.competition_name}</span>
        <div className="h-3.5 w-px bg-gray-300" />
        <span className="text-xs font-semibold">{match.season}</span>
        <div className="h-3.5 w-px bg-gray-300" />
        <span className="text-xs font-semibold">{date.toLocaleDateString()}</span>
      </div>
    </>
  );
};

export default memo(ElementMatchInfo);
