import { Table } from '@tanstack/react-table';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import UserCard from 'modules/common/UserCard';
import { memo, useEffect, useState } from 'react';
import { DialogProps, UserRow } from 'utils/interfaces';

interface DeleteUsersDialogProps extends DialogProps {
  table: Table<UserRow>;
  deleteUsers: (users: UserRow[]) => void;
}

function DeleteUsersDialog({ table, deleteUsers, open, setOpen }: DeleteUsersDialogProps) {
  const [selectedUsers, setSelectedUsers] = useState<UserRow[]>([]);

  function handleCancel() {
    setOpen(false);
  }

  function handleDelete() {
    deleteUsers(selectedUsers);
  }

  useEffect(() => {
    setSelectedUsers(table.getSelectedRowModel().flatRows.map((row) => row.original));
  }, [table, open]);

  return (
    <DialogBase title="Delete accounts" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex flex-col gap-6">
          <span className="text-md font-medium">You are about to delete the following accounts.</span>
          <div className="flex flex-col gap-3">
            {selectedUsers.map((user) => (
              <div key={user.name} className="rounded-md bg-gray-50 px-3 py-2">
                <UserCard name={user.name!} email={user.email!} initials={user.initials!} image={user.image} />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="red" size="lg" onClick={handleDelete}>
          Delete Accounts
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(DeleteUsersDialog);
