import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { loadAttributeValues } from './helpers';
import { Metric, MetricOption, ValueOption } from 'utils/interfaces';
import { ElementFormProps, RadarChartElementFormValues } from './interfaces';
import AggregateOptions from './AggregateOptions';
import { metricToMetricOption, playerToValueOption, stringToValueOption } from 'utils/mappings';

const RadarChartElementForm = memo(function RadarChartElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<RadarChartElementFormValues> = {};
  if (element?.attribute_values) {
    // Editing element
    loadAttributeValues(defaultValues, element, template);
    defaultValues.aggregate_by = {
      label: 'Match',
      id: 'match'
    };
  } else {
    // Creating element
    defaultValues.aggregate_by = {
      label: 'Match',
      id: 'match'
    };
    switch (report.report_type) {
      case 'team_comparison':
        defaultValues.attribute_type = {
          label: 'Team',
          id: 'team'
        };
        break;
      default:
        defaultValues.attribute_type = {
          label: 'Player',
          id: 'player'
        };
        break;
    }
  }
  
  const radarForm = useForm<RadarChartElementFormValues>({
    defaultValues
  });
  const { control, watch, handleSubmit, setValue, getValues, formState } = radarForm;
  const attributeType = watch('attribute_type');
  const playerMetrics = watch('metrics_players_match');
  const teamMetrics = watch('metrics_teams_match');

  const addElement = useCallback(
    function addElement(data: RadarChartElementFormValues) {
      if (template!.attribute_choices!.players) {
        const players = getValues('players');
        if (attributeType?.id === 'player' && players.length === 0) {
          data.players = template.attribute_choices!.players.map(playerToValueOption);
        }
      }

      onSubmitForm(data);
    },
    [attributeType?.id, getValues, onSubmitForm, template]
  );

  const attributeTypeOptions = useMemo(
    () => template.attribute_choices!.attribute_type?.map(stringToValueOption) || [],
    [template.attribute_choices]
  );

  const playerOptions = useMemo(
    () => template.attribute_choices!.players?.map(playerToValueOption) || [],
    [template.attribute_choices]
  );

  // Resetting values on change
  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('players', []);
      setValue('metrics_players_match', []);
    } else {
      setValue('metrics_teams_match', []);
    }
  }, [attributeType, setValue]);

  const active_metrics: MetricOption[] = useMemo(() => {
    if (attributeType?.id === 'team') {
      return teamMetrics;
    } else {
      return playerMetrics;
    }
  }, [attributeType, playerMetrics, teamMetrics]);

  const metricsTeamsMatchOptions = useMemo(() => {
    const metrics = (template.attribute_choices!.metrics_teams_match as Metric[]) || [];
    const filteredMetrics = metrics.filter((metric) => metric.min_value >= 0);
    return filteredMetrics.map(metricToMetricOption);
  }, [template.attribute_choices]);

  const metricsPlayersMatchOptions = useMemo(() => {
    const metrics = (template.attribute_choices!.metrics_players_match as Metric[]) || [];
    const filteredMetrics = metrics.filter((metric) => metric.min_value >= 0);
    return filteredMetrics.map(metricToMetricOption);
  }, [template.attribute_choices]);

  const renderMetrics = useCallback(() => {
    let active_metric = '';
    let metric_options = [];
    switch (attributeType?.id) {
      case 'team':
        active_metric = 'metrics_teams_match';
        metric_options = metricsTeamsMatchOptions;
        break;
      case 'player':
        active_metric = 'metrics_players_match';
        metric_options = metricsPlayersMatchOptions;
        break;
      default:
        return (
          <MultipleSelectInput
            control={control}
            key={'placeholder'}
            name="placeholder"
            label={'Select aggregation data'}
            disabled={true}
            options={[]}
          />
        );
    }

    return (
      <MultipleSelectInput
        key={active_metric}
        name={active_metric}
        control={control}
        label={'Metrics'}
        placeholder={'Choose metrics to aggregate'}
        disabled={attributeType === null}
        options={metric_options}
        rules={{
          validate: {
            minLength: (values: ValueOption[]) => values.length > 2 || 'At least three metrics are required!'
          }
        }}
        error={formState.errors[active_metric]}
      />
    );
  }, [attributeType, control, formState.errors, metricsPlayersMatchOptions, metricsTeamsMatchOptions]);

  return (
    <FormProvider {...radarForm}>
      <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
        {template.attribute_choices!.attribute_type && (
          <SingleSelectInput
            name={'attribute_type'}
            control={control}
            label={'Attribute type'}
            options={attributeTypeOptions}
            disabled={attributeTypeOptions.length === 1}
            rules={{ required: 'Attribute type is required!' }}
            error={formState.errors.attribute_type}
          />
        )}
        {renderMetrics()}
        {report.report_type !== 'match' && <AggregateOptions template={template} metrics={active_metrics} />}
        {attributeType?.id === 'player' && template.attribute_choices!.players && (
          <MultipleSelectInput
            name={'players'}
            control={control}
            label={'Players'}
            placeholder="All"
            options={playerOptions}
          />
        )}
      </form>
    </FormProvider>
  );
});

export default RadarChartElementForm;
