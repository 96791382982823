import SearchEye from 'assets/search-eye.svg?react';
import useAuth from 'contexts/auth/authContext';
import { useObjectsOptions } from 'hooks/useOptions';
import { ProjectGetSchema } from 'lib/model';
import { useGetUserClub } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Combobox from 'modules/common/Form/Combobox';
import Input from 'modules/common/Form/Input';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { DialogProps, ValueOption } from 'utils/interfaces';

interface OrganizationEditFormValues {
  organization: ValueOption;
  name?: string;
  country?: string;
  city?: string;
  zipCode?: string;
}

interface OrganizationEditDialogProps extends DialogProps {
  org: ProjectGetSchema;
}

const notInListText =
  'No worries though! For security reasons, we will add the organisation on our list. We’ll contact you shortly at:';
const mismatchText =
  'No worries though! For security reasons, we will double-check this for you. We’ll contact you shortly at:';
const notInListSubtitle = "It looks like your organization isn't on our list just yet.";
const mismatchSubtitle = "It seems there's a slight mismatch between your email and organization name.";

function OrganizationEditDialog({ open, setOpen, org }: OrganizationEditDialogProps) {
  const { user } = useAuth();
  const [changeInitiated, setChangeInitiated] = useState(false);
  const { handleSubmit, register, reset, control, watch, formState, trigger } = useForm<OrganizationEditFormValues>({
    defaultValues: {
      organization: { label: org.organization_name, id: org.id }
    }
  });
  const selectedOrg = watch('organization');

  const { data: organizations, isPending: isOrganiationsPending } = useGetUserClub({
    query: { queryKey: ['organizations'] }
  });
  const organizationOptions = useObjectsOptions(organizations, {
    defaultOptions: [{ label: 'Not listed', id: '' }]
  });

  function handleCancel() {
    reset();
    setOpen(false);
    setChangeInitiated(false);
  }

  function initiateChange(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    trigger('organization');
    if (formState.isValid) {
      setChangeInitiated(true);
    }
  }

  function onEmailChangeSubmit(data: OrganizationEditFormValues) {
    // eslint-disable-next-line no-console
    console.log(data);
  }

  return (
    <DialogBase title="Edit organization" open={open} onCancel={handleCancel} narrower={changeInitiated}>
      <DialogContent>
        {!changeInitiated ? (
          <form
            onSubmit={handleSubmit(onEmailChangeSubmit)}
            className="flex flex-col gap-6"
            id="organization-edit-form"
          >
            <Combobox
              control={control}
              name="organization"
              label="Organization"
              options={organizationOptions}
              loading={isOrganiationsPending}
              rules={{ required: { value: true, message: 'Organization is required' } }}
              error={formState.errors.organization}
            />
            <div className={twMerge('hidden flex-col gap-6', (selectedOrg?.id as string)?.length === 0 && 'flex')}>
              <Input registerReturn={register('name')} label="Enter organization name" />
              <Input registerReturn={register('country')} label="Country" />
              {/* <SingleSelectInput control={control} name="country" label="County" options={organizationOptions} /> */}
              <div className="flex justify-between gap-6">
                {/* <SingleSelectInput control={control} name="city" label="City" options={organizationOptions} /> */}
                <Input registerReturn={register('city')} label="City" fullWidth />
                <Input registerReturn={register('zipCode')} label="Zip Code" fullWidth />
              </div>
            </div>
          </form>
        ) : (
          <div className="flex flex-col items-center gap-10">
            <div className="flex flex-col gap-6 text-center">
              <span className="text-h6 text-gray-900">Editing your Organization is almost complete</span>
              <span className="text-md font-semibold text-gray-900">
                {(selectedOrg?.id as string)?.length === 0 ? notInListSubtitle : mismatchSubtitle}
              </span>
            </div>
            <SearchEye className="my-10 size-16 fill-brand-800" />
            <div className="flex flex-col gap-6">
              <span className="text-center text-md">
                {(selectedOrg?.id as string)?.length === 0 ? notInListText : mismatchText}
                <br />
                <b>{user.email}</b>
              </span>
              <span className="text-center text-md font-semibold">
                If you proceed with this change, your Organization will be blocked until the issue is resolved. This
                process usually takes up to 24 hours.
              </span>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        {changeInitiated ? (
          <Button variant="red" size="xl" isSubmitButton form="organization-edit-form">
            Change organization
          </Button>
        ) : (
          <Button variant="primary" size="xl" onClick={initiateChange}>
            Change organization
          </Button>
        )}
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(OrganizationEditDialog);
