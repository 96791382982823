import Success from 'assets/check-circle.svg?react';
import Loading from 'assets/loading.svg?react';
import Warning from 'assets/warning.svg?react';
import { memo } from 'react';
import { Slide, ToastClassName, ToastContainer } from 'react-toastify';
import { ToastIcon } from 'react-toastify/dist/types';
import { twJoin } from 'tailwind-merge';

const Toast = memo(function Toast() {
  return (
    <ToastContainer
      hideProgressBar={true}
      position="bottom-center"
      transition={Slide}
      autoClose={5000}
      toastClassName={toastClassName}
      icon={toastIcon}
      closeButton={false}
      pauseOnHover
      closeOnClick={true}
      bodyClassName={'text-sm font-medium flex gap-2.5 items-center block py-2 px-2.5 rounded-md'}
    />
  );
});

const toastClassName: ToastClassName = (context) => {
  return twJoin(
    'relative rounded-md flex justify-between overflow-hidden text-white cursor-pointer my-2',
    context?.type === 'success' && 'bg-green-600',
    context?.type === 'info' && 'bg-gray-950', // for now info is used for "loading" information
    context?.type === 'warning' && 'bg-amber-600',
    context?.type === 'error' && 'bg-red-600',
    context?.type === 'default' && 'bg-brand-800'
  );
};

const toastIcon: ToastIcon = (context) => {
  switch (context.type) {
    case 'success':
      return <Success width={20} height={20} className="fill-white" />;
    case 'info':
      return <Loading width={20} height={20} className="animate-spin fill-white" />;
    case 'error':
      return <Success width={20} height={20} className="fill-white" />;
    case 'warning':
      return <Warning width={20} height={20} className="fill-white" />;
    default:
      return null;
  }
};

export default Toast;
