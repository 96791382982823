import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { getInitials } from 'utils/helpers';

interface DataSourceTitleProps {
  name: string;
  size?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  imageSize?: 'lg' | 'md' | 'sm';
  image?: string;
}

const DataSourceTitle = memo(function DataSourceTitle({
  name,
  size = 'sm',
  imageSize = 'md',
  image = undefined
}: DataSourceTitleProps) {
  return (
    <div className="flex grow items-center gap-3">
      <div
        className={twJoin(
          'brand-gradient flex items-center justify-center rounded-lg border border-gray-200 font-semibold',
          imageSize === 'lg' && 'h-10 w-10 text-lg',
          imageSize === 'md' && 'h-8 w-8 text-md',
          imageSize === 'sm' && 'h-6 w-6 text-sm'
        )}
      >
        {image ? (
          <img
            className={twJoin(
              'rounded-lg',
              imageSize === 'lg' && 'h-10 w-10 text-lg',
              imageSize === 'md' && 'h-8 w-8 text-md',
              imageSize === 'sm' && 'h-6 w-6 text-sm'
            )}
            src={image}
          />
        ) : (
          <span className="text-white">{getInitials(name)}</span>
        )}
      </div>
      <span
        className={twJoin(
          'font-semibold text-gray-900',
          size === 'tiny' && 'text-tiny',
          size === 'xs' && 'text-xs',
          size === 'sm' && 'text-sm',
          size === 'md' && 'text-md',
          size === 'lg' && 'text-lg',
          size === 'xl' && 'text-xl'
        )}
      >
        {name}
      </span>
    </div>
  );
});

export default DataSourceTitle;
