import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import PlayerPositionChart from './PlayerPositionChart';
import { PositionMapPoint } from './interfaces';
import { MatchDetailed } from 'utils/interfaces';
import ElementMatchInfo from '../ElementMatchInfo';

interface PositionMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

interface PositionMapData {
  'avg-positions-home': PositionMapPoint[];
  'avg-positions-away': PositionMapPoint[];
  match_info?: MatchDetailed;
}

const PositionMapElement = memo(function PositionMapElement({ element, report }: PositionMapElementProps) {
  const data = element.entity_data as PositionMapData;
  const match = data.match_info;

  return (
    <div className="flex flex-col gap-6">
      {report.report_type === 'team' && match && <ElementMatchInfo match={match} />}
      <PlayerPositionChart
        away={data['avg-positions-away'] ?? []}
        home={data['avg-positions-home'] ?? []}
        report={report}
      />
    </div>
  );
});

export default PositionMapElement;
