/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Label, Listbox, ListboxButton } from '@headlessui/react';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loading from 'assets/loading.svg?react';
import { memo, useCallback } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { ValueOption } from 'utils/interfaces';
import ErrorMessage from './ErrorMessage';
import InfoMessage from './InfoMessage';
import ValueBadge from './ValueBadge';
import ValueOptionsDropdown from './ValueOptionsDropdown';

interface MultipleSelectInputProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  options: ValueOption[];
  searchable?: boolean;
  minSearchQuery?: number;
  error?: Merge<FieldError, FieldErrorsImpl<ValueOption[]>>;
  loading?: boolean;
  info?: string;
}

function MultipleSelectInput<T extends FieldValues>({
  label,
  placeholder,
  options,
  error,
  info,
  minSearchQuery,
  loading = false,
  defaultValue = [] as PathValue<T, Path<T>>,
  disabled = false,
  searchable = false,
  ...formProps
}: MultipleSelectInputProps<T>) {
  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController<T>({ ...formProps, defaultValue });

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const handleRemove = useCallback(
    function handleRemove(removedValue: string) {
      formOnChange((formValues as ValueOption[]).filter((x) => x.label !== removedValue));
    },
    [formOnChange, formValues]
  );

  const handleChange = useCallback(
    function handleChange(val: ValueOption[]) {
      formOnChange(val);
    },
    [formOnChange]
  );

  const noOptions = !loading && !disabled && options !== undefined && options.length === 0;

  return (
    <div className="flex w-full flex-col gap-2">
      <Listbox
        disabled={disabled || noOptions}
        multiple={true}
        className={'relative'}
        as={'div'}
        value={(formValues ?? []) as ValueOption[]}
        onChange={handleChange}
        by="id"
      >
        {({ open, value: selectedValues }) => (
          <>
            <Label
              className={twMerge(
                'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                (Boolean(placeholder) || (selectedValues && selectedValues.length > 0)) && '-translate-y-0 text-xs'
              )}
            >
              {label}
            </Label>
            <div className="relative">
              <ListboxButton
                ref={refs.setReference}
                className="relative min-h-14 w-full cursor-pointer rounded-md border border-gray-300 bg-white pb-1.5 pl-3 pr-6 pt-6 text-left focus:outline-none focus:ring-0 ui-disabled:bg-gray-50"
              >
                {selectedValues && selectedValues.length > 0 ? (
                  <div className="flex w-full flex-wrap gap-2">
                    {selectedValues.map((selectedValue: ValueOption) => (
                      <ValueBadge value={selectedValue.label} key={selectedValue.id} onRemove={handleRemove} />
                    ))}
                  </div>
                ) : (
                  <span className="text-md">{placeholder}</span>
                )}
                {loading ? (
                  <Loading
                    aria-hidden="true"
                    width={20}
                    height={20}
                    className="pointer-events-none absolute inset-y-4.5 right-3 z-10 animate-spin fill-gray-700"
                  />
                ) : (
                  <ChevronDown
                    aria-hidden="true"
                    width={20}
                    height={20}
                    className="pointer-events-none absolute inset-y-4.5 right-3 z-10 fill-gray-700"
                  />
                )}
              </ListboxButton>
              {!noOptions && !loading && open && (
                <ValueOptionsDropdown
                  floatingStyles={floatingStyles}
                  ref={refs.setFloating}
                  options={options}
                  searchable={searchable}
                  minSearchQuery={minSearchQuery}
                />
              )}
            </div>
          </>
        )}
      </Listbox>
      {error && <ErrorMessage error={error} />}
      {info && <InfoMessage info={info} />}
      {noOptions && <InfoMessage info={`${label} has no options available`} />}
    </div>
  );
}

export default memo(MultipleSelectInput) as typeof MultipleSelectInput;
