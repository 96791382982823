import { seasonOptions } from 'constants/generated';
import { SelectedDataSource } from 'contexts/selectedDataSourcesContext';
import { memo } from 'react';

const ConfigurationDisplay = memo(function ConfigurationDisplay({ dataSource, state }: SelectedDataSource) {
  const allLeagues = !dataSource.datasource_competitions;
  const allSeasons = !state.configuration?.min_season;
  const refreshFrequency = state.configuration?.refreshFrequency
    ? state.configuration?.refreshFrequency.label
    : 'Default';

  return (
    <div className="flex flex-col items-start gap-3">
      <span className="text-xs font-medium">Competitions</span>
      <div className="flex flex-wrap justify-start gap-1">
        {allLeagues ? (
          <span className="rounded bg-gray-100 px-1.5 py-0.5 text-sm">All competitions</span>
        ) : (
          state.configuration?.leagues.map((l) => (
            <span key={l.id} className="rounded bg-gray-100 px-1.5 py-0.5 text-sm">
              {l.label}
            </span>
          ))
        )}
      </div>
      <span className="text-xs font-medium">Earliest season</span>
      <div className="flex flex-wrap justify-start gap-1">
        {allSeasons ? (
          <span className="rounded bg-gray-100 px-1.5 py-0.5 text-sm">Default (earliest available)</span>
        ) : (
            <span key={state.configuration?.min_season.id} className="rounded bg-gray-100 px-1.5 py-0.5 text-sm">
              {state.configuration?.min_season.label}
            </span>
        )}
      </div>
      <span className="text-xs font-medium">Refresh frequency</span>
      <span className="text-xs">{refreshFrequency}</span>
    </div>
  );
});

export default ConfigurationDisplay;
