import { ValueOption } from 'utils/interfaces';

function generateSeasons(): ValueOption[] {
  const currentYear = new Date().getFullYear();
  const startYear = 2019;
  const yearRanges: ValueOption[] = [];

  for (let year = currentYear - 1; year >= startYear; year--) {
    yearRanges.push({ id: year.toString(), label: `${year}/${year + 1}` });
  }

  return yearRanges;
}

function generateCRONHoursArray(): ValueOption[] {
  const hoursArray: ValueOption[] = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 30) {
      const period = h < 12 ? 'AM' : 'PM';
      const hr = (h % 12 || 12).toString().padStart(2, '0');
      const min = m.toString().padStart(2, '0');
      hoursArray.push({ id: `${m} ${h}`, label: `${hr}:${min} ${period}` });
    }
  }
  return hoursArray;
}

function generateHoursArray(): ValueOption[] {
  const hoursArray: ValueOption[] = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 30) {
      const period = h < 12 ? 'AM' : 'PM';
      const hr = (h % 12 || 12).toString().padStart(2, '0');
      const min = m.toString().padStart(2, '0');
      hoursArray.push({ id: `${h.toString().padStart(2, '0')}:${min}`, label: `${hr}:${min} ${period}` });
    }
  }
  return hoursArray;
}

export const timeOptions = generateHoursArray();
export const CRONTimeOptions = generateCRONHoursArray();
export const seasonOptions = generateSeasons();
