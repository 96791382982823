import Logo from 'assets/logo.svg?react';
import { AxiosError } from 'axios';
import useIsMobile from 'hooks/useIsMobile';
import { ProjectRoleSchemaName } from 'lib/model';
import { useGetProjectRole } from 'lib/project-role/project-role';
import { usePostProjectProjectIdInvite } from 'lib/project/project';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import MultipleValueInput from 'modules/common/Form/MultipleValueInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

interface InviteUsersFormValues {
  emails: string[];
}

interface InviteUsersProps {
  organizationName: string;
  projectId: string;
  image: string | undefined;
  refreshInviteUsersState: (skip?: boolean) => void;
}

const InviteUsers = memo(function InviteUsers({
  organizationName,
  projectId,
  image,
  refreshInviteUsersState
}: InviteUsersProps) {
  const isMobile = useIsMobile();
  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<InviteUsersFormValues>({
    defaultValues: {
      emails: []
    }
  });
  const { data: roles } = useGetProjectRole();
  const { mutate: sendInvites } = usePostProjectProjectIdInvite();

  function skipInviting() {
    localStorage.setItem('inviteUsersState', 'skipped');
    refreshInviteUsersState(true);
  }

  function onSubmit(data: InviteUsersFormValues) {
    sendInvites(
      {
        projectId: projectId,
        data: {
          emails: data.emails,
          project_role_id: roles!.objects!.find((x) => x.name === ProjectRoleSchemaName.viewer)!.id!
        }
      },
      {
        onSuccess: () => {
          localStorage.setItem('inviteUsersState', 'done');
          refreshInviteUsersState();
        },
        onError(err) {
          if (err instanceof AxiosError) {
            const error = err.response?.data.error
            toast.error(error);
          }
        }
      }
    );
  }

  return (
    <div className="brand-gradient flex min-h-screen w-screen flex-col items-center">
      <div className={twMerge('flex h-[72px] w-full items-center bg-white px-8', isMobile && 'bg-gray-950')}>
        <Logo width={86} height={28} className={isMobile ? 'fill-white' : 'fill-gray-900'} />
      </div>
      <div className={twMerge('flex h-full w-full flex-col items-center justify-center pt-10', isMobile && 'pt-0')}>
        <div className="flex w-full max-w-3xl flex-col gap-10 rounded-3xl bg-white p-16">
          <div className="flex items-center gap-2">
            <CircleImage image={image} text={organizationName.charAt(0)} size="size-8" />
            <h2 className="text-2xl font-semibold">{organizationName}</h2>
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold text-gray-900">Invite User</span>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
              <MultipleValueInput
                name="emails"
                initialPlaceholder="Ex: user.name@gmail.com, user.name@yahoo.com"
                label="Enter emails"
                control={control}
                rules={{
                  validate: {
                    minLength: (values: string[]) => (values && values.length > 0) || 'At least one email is required'
                  }
                }}
              />
              <div className="flex gap-6">
                <Button isSubmitButton={true} size="xl" disabled={!isValid}>
                  Finish
                </Button>
              </div>
            </form>
          </div>
          <div className="flex flex-col items-center">
            <button className="text-md font-medium text-brand-800" onClick={skipInviting}>
              I'll do it later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default InviteUsers;
