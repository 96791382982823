import Time from 'assets/time.svg?react';
import { dayOfMonthOptions, dayOfWeekOptions, sendReportScheduleFrequencyOptions } from 'constants/formOptions';
import { timeOptions } from 'constants/generated';
import { ReportSendScheduleSchema } from 'lib/model';
import Button from 'modules/common/Button';
import Checkbox from 'modules/common/Form/Checkbox';
import Input from 'modules/common/Form/Input';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { ScheduleReportFormValues } from './interfaces';

interface ScheduleFormUpdateScheduleProps {
  schedule?: ReportSendScheduleSchema;
  onUpdateSchedule: (data: ScheduleReportFormValues) => void;
  onCancel: () => void;
}

function ScheduleFormUpdateSchedule({ schedule, onUpdateSchedule, onCancel }: ScheduleFormUpdateScheduleProps) {
  const { watch, control, register, handleSubmit } = useForm<ScheduleReportFormValues>({
    defaultValues: {
      emails: schedule?.user_emails,
      scheduleFrequency: schedule?.frequency
        ? sendReportScheduleFrequencyOptions.find((option) => option.id === schedule.frequency)
        : sendReportScheduleFrequencyOptions[0],
      scheduleTimeOfDay: schedule?.time_of_day
        ? timeOptions.find((option) => option.id + ':00' === schedule.time_of_day)
        : timeOptions[18],
      scheduleDayOfWeek: schedule?.day_of_week
        ? dayOfWeekOptions.find((option) => option.id === schedule.day_of_week)
        : dayOfWeekOptions[0],
      scheduleDayOfMonth: schedule?.day_of_month
        ? dayOfMonthOptions.find((option) => option.id === schedule.day_of_month)
        : dayOfMonthOptions[0],
      isEndDateEnabled: !!schedule?.end_date,
      scheduleEndDate: schedule?.end_date
    }
  });
  const scheduleFrequency = watch('scheduleFrequency');

  const updateSchedule = useCallback(
    function saveSchedule(data: ScheduleReportFormValues) {
      onUpdateSchedule(data);
    },
    [onUpdateSchedule]
  );

  return (
    <form
      className="flex flex-col gap-6 rounded-md bg-gray-50 p-6"
      id="update-schedule-form"
      onSubmit={handleSubmit(updateSchedule)}
    >
      <div className="flex items-center gap-2">
        <Time className="size-6 fill-gray-700" />
        <span className="text-md font-semibold">Schedule</span>
        <span className="text-sm font-semibold">[Note: all times are in UTC]</span>
      </div>
      <SingleSelectInput
        control={control}
        name="scheduleFrequency"
        label="Send"
        options={sendReportScheduleFrequencyOptions}
      />
      {scheduleFrequency?.id === 'daily' && (
        <SingleSelectInput control={control} name="scheduleTimeOfDay" label="At" options={timeOptions} />
      )}
      {scheduleFrequency?.id === 'weekly' && (
        <div className="flex gap-6">
          <SingleSelectInput control={control} name="scheduleDayOfWeek" label="On" options={dayOfWeekOptions} />
          <SingleSelectInput control={control} name="scheduleTimeOfDay" label="At" options={timeOptions} />
        </div>
      )}
      {scheduleFrequency?.id === 'monthly' && (
        <div className="flex gap-6">
          <SingleSelectInput control={control} name="scheduleDayOfMonth" label="On" options={dayOfMonthOptions} />
          <SingleSelectInput control={control} name="scheduleTimeOfDay" label="At" options={timeOptions} />
        </div>
      )}
      <div className="flex flex-col gap-6">
        <Checkbox registerReturn={register('isEndDateEnabled')} label="End Date" />
        {watch('isEndDateEnabled') && (
          <Input type="date" registerReturn={register('scheduleEndDate')} label="Ends on" />
        )}
      </div>
      <div className="ml-auto flex w-1/2 items-center gap-2.5">
        <Button variant="secondary" size="lg" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="lg" isSubmitButton form="update-schedule-form">
          Save
        </Button>
      </div>
    </form>
  );
}

export default memo(ScheduleFormUpdateSchedule);
