import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { snakeCaseToWords } from 'utils/helpers';
import { Player, ValueOption } from 'utils/interfaces';
import { ElementFormProps, ElementFormValues } from './interfaces';
import { playerToValueOption } from 'utils/mappings';

// shouldn't be used
const DynamicElementForm = memo(function DynamicElementForm({ template, onSubmitForm }: ElementFormProps) {
  const { handleSubmit, control } = useForm<ElementFormValues>({});

  const addElement = useCallback(
    function addElement(data: ElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      {Object.entries(template.attribute_choices!).map(([key, options]: [string, string[] | number[] | Player[]]) => {
        if (typeof options[0] === 'object' && 'player_id' in options[0]) {
          const players = options as Player[];
          return (
            <MultipleSelectInput
              key={key}
              name={key}
              control={control}
              label={snakeCaseToWords(key)}
              options={players.map(playerToValueOption)}
            />
          );
        }
        return (
          <MultipleSelectInput
            key={key}
            name={key}
            control={control}
            label={snakeCaseToWords(key)}
            options={options.map(
              (option) =>
                ({
                  label: option,
                  id: option
                }) as ValueOption
            )}
          />
        );
      })}
    </form>
  );
});

export default DynamicElementForm;
