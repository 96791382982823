import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { loadAttributeValues } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';
import { eventToValueOption } from 'utils/mappings';

interface EventMapElementFormValues extends ElementFormValues {
  events: ValueOption | null;
}

const EventMapForm = memo(function VersusElementForm({ template, onSubmitForm, element }: ElementFormProps) {
  const defaultValues: Partial<EventMapElementFormValues> = {};
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  }

  const { handleSubmit, control, formState } = useForm<EventMapElementFormValues>({
    defaultValues
  });

  const addElement = useCallback(
    function addElement(data: EventMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  const eventOptions: ValueOption[] = useMemo(() => {
    return template.attribute_choices!.events?.map(eventToValueOption) || [];
  }, [template.attribute_choices]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SingleSelectInput
        name="events"
        control={control}
        label={'Event'}
        options={eventOptions}
        rules={{ required: { value: true, message: 'Event is required.' } }}
        error={formState.errors.events}
      />
    </form>
  );
});

export default EventMapForm;
