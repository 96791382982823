import useInitialDataLoad from 'hooks/useInitialDataLoad';
import useIsMobile from 'hooks/useIsMobile';
import useWindowSize from 'hooks/useWindowSize';
import { SubscriptionGetSchema } from 'lib/model';
import Button from 'modules/common/Button';
import { memo, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import ScrollToTop from 'utils/scrollToTop';
import InviteUsers from './components/InviteUsers';
import MobileNavigation from './components/MobileNavigation';
import SideNavigation from './components/SideNavigation';
import WelcomeScreen from './components/WelcomeScreen';

const SidebarLayout = memo(function SidebarLayout() {
  const windowSize = useWindowSize();
  const isMobile = useIsMobile();
  const [inviteUsersState, setInviteUsersState] = useState(localStorage.getItem('inviteUsersState'));
  const [expanded, setExpanded] = useState<boolean>(windowSize > 1280);
  const { project, isLoading, shouldInviteUsers, activeTier } = useInitialDataLoad();
  const location = useLocation();
  const navigate = useNavigate();

  const expirationDays =
    activeTier && (activeTier as SubscriptionGetSchema).subscription_end
      ? Math.round(
          (new Date((activeTier as SubscriptionGetSchema).subscription_end!).getTime() - new Date().getTime()) /
            (1000 * 3600 * 24)
        )
      : 0;

  function goToTierPlans() {
    navigate('/settings/tier-plans');
  }

  useEffect(
    function onNarrowScreens() {
      setExpanded(windowSize > 1280);
    },
    [windowSize]
  );

  const refreshInviteUsersState = useCallback(function refreshInviteUsersState(skip?: boolean) {
    if (skip) {
      setInviteUsersState('skip');
    } else {
      setInviteUsersState(localStorage.getItem('inviteUsersState'));
    }
  }, []);

  if (isLoading) {
    return <WelcomeScreen />;
  }

  if (shouldInviteUsers && (!inviteUsersState || inviteUsersState === 'skipped')) {
    return (
      <InviteUsers
        organizationName={project!.organization_name!}
        projectId={project!.id!}
        refreshInviteUsersState={refreshInviteUsersState}
        image={project?.organization_image_path}
      />
    );
  }

  return (
    <div className="flex max-w-[100vw] max-md:flex-col">
      <ScrollToTop />
      {!isMobile ? <SideNavigation expanded={expanded} setExpanded={setExpanded} /> : <MobileNavigation />}
      <section
        className={twMerge(
          'flex min-h-screen w-full flex-col items-center px-6 pb-6 max-md:px-4 max-md:pb-4',
          expanded ? 'md:max-w-[calc(100vw_-280px)]' : 'md:max-w-[calc(100vw_-_108px)]'
        )}
      >
        <Outlet />
      </section>
      {(activeTier as SubscriptionGetSchema).subscription_type === 'trial' &&
        location.pathname !== '/settings/tier-plans' && (
          <div
            className={twMerge(
              'fixed bottom-3 right-1 z-10 mx-2 flex h-12 w-fit items-center justify-center gap-6 rounded-md bg-brand-800 px-2',
              isMobile && 'h-auto max-w-36 flex-col gap-2 p-2'
            )}
          >
            <div className={twJoin('text-sm font-medium text-white', isMobile && 'text-center')}>
              <b>
                {expirationDays} {expirationDays > 1 ? 'days' : 'day'}
              </b>{' '}
              until trial expiration
            </div>
            <Button variant="white" size="md" isFullWidth={false} onClick={goToTierPlans}>
              Upgrade plan
            </Button>
          </div>
        )}
    </div>
  );
});

export default SidebarLayout;
