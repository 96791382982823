import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { useGetReportGetPlayers, usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Combobox from 'modules/common/Form/AsyncCombobox';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { conditionallyRender } from 'utils/helpers';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';
import { playerToAutocompleteOption } from 'utils/mappings';

interface NewPlayerComparisonReportFormValues {
  name: string;
  player1: AutocompleteOption | null;
  player2: AutocompleteOption | null;
}

const NewPlayerComparisonReportDialog = memo(function NewPlayerComparisonReportDialog({ open, setOpen }: DialogProps) {
  const [player1Query, setPlayer1Query] = useState<string>('');
  const [player2Query, setPlayer2Query] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    setError,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<NewPlayerComparisonReportFormValues>({
    defaultValues: {
      name: '',
      player1: null,
      player2: null
    }
  });

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;

  const { data: players1, isFetching: isFetchingPlayers1 } = useGetReportGetPlayers(
    {
      player_name: player1Query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['players', project.id, player1Query],
        staleTime: 1000 * 60 * 5,
        enabled: player1Query.length > 0
      }
    }
  );
  const player1Options = useMemo(() => {
    return players1?.players?.map(playerToAutocompleteOption) ?? [];
  }, [players1]);

  const { data: players2, isFetching: isFetchingPlayers2 } = useGetReportGetPlayers(
    {
      player_name: player2Query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['players', project.id, player2Query],
        staleTime: 1000 * 60 * 5,
        enabled: player2Query.length > 0
      }
    }
  );
  const player2Options = useMemo(() => {
    return players2?.players?.map(playerToAutocompleteOption) ?? [];
  }, [players2]);

  const { mutate: createReport, isPending: isCreating } = usePostReport();

  function handleSubmit(data: NewPlayerComparisonReportFormValues) {
    if (!data.player1 || !data.player2) return;
    if (data.player1.id === data.player2.id) {
      setError('root', { message: "Can't compare between identical players", type: 'validate' });
      return;
    }
    createReport(
      {
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.player_comparison,
          project: project!.id!,
          report_entities: [data.player1.id as number, data.player2.id as number] // TODO: figure out the format
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedPlayer1 = watch('player1');
  const selectedPlayer2 = watch('player2');
  useEffect(() => {
    if (selectedPlayer1 && selectedPlayer2) {
      setValue('name', `${selectedPlayer1.label} - ${selectedPlayer2.label}`);
    } else {
      setValue('name', '');
    }
  }, [selectedPlayer1, selectedPlayer2, setValue]);

  return (
    <DialogBase title="New Player Comparison Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form
          className="flex w-full flex-col gap-10"
          id="new-player-comparison-report-form"
          onSubmit={formSubmit(handleSubmit)}
        >
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Players</span>
            <Combobox
              rules={{ required: { value: true, message: 'Player is required.' } }}
              error={errors.player1}
              loading={isFetchingPlayers1}
              setQuery={setPlayer1Query}
              name="player1"
              control={control}
              label="Player 1"
              placeholder="Start typing: Player name / Team / Player country"
              options={player1Options}
            />
            <Combobox
              rules={{ required: { value: true, message: 'Player is required.' } }}
              error={errors.player2}
              loading={isFetchingPlayers2}
              setQuery={setPlayer2Query}
              name="player2"
              control={control}
              label="Player 2"
              placeholder="Start typing: Player name / Team / Player country"
              options={player2Options}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-player-comparison-report-form" loading={isCreating}>
          <span>Create Player Comparison Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewPlayerComparisonReportDialog;
