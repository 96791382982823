import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { useGetReportGetTeams, usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Combobox from 'modules/common/Form/AsyncCombobox';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';
import { teamToAutocompleteOption } from 'utils/mappings';

interface NewTeamComparisonReportFormValues {
  name: string;
  team1: AutocompleteOption | null;
  team2: AutocompleteOption | null;
}

const NewTeamComparisonReportDialog = memo(function NewTeamComparisonReportDialog({ open, setOpen }: DialogProps) {
  const [team1Query, setTeam1Query] = useState<string>('');
  const [team2Query, setTeam2Query] = useState<string>('');
  const navigate = useNavigate();
  const {
    handleSubmit: formSubmit,
    register,
    control,
    setError,
    setValue,
    watch,
    formState: { errors }
  } = useForm<NewTeamComparisonReportFormValues>({
    defaultValues: {
      name: '',
      team1: null,
      team2: null
    }
  });

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;

  const { data: teams1, isFetching: isFetchingTeams1 } = useGetReportGetTeams(
    {
      team_name: team1Query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['teams', project.id, team1Query],
        staleTime: 1000 * 60 * 5,
        enabled: team1Query.length > 0
      }
    }
  );
  const team1Options = useMemo(() => {
    return teams1?.teams?.map(teamToAutocompleteOption) ?? [];
  }, [teams1]);
  const { data: teams2, isFetching: isFetchingTeams2 } = useGetReportGetTeams(
    {
      team_name: team2Query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['teams', project.id, team2Query],
        staleTime: 1000 * 60 * 5,
        enabled: team2Query.length > 0
      }
    }
  );
  const team2Options = useMemo(() => {
    return teams2?.teams?.map(teamToAutocompleteOption) ?? [];
  }, [teams2]);

  const { mutate: createReport, isPending: isCreating } = usePostReport();

  function handleSubmit(data: NewTeamComparisonReportFormValues) {
    if (!data.team1 || !data.team2) return;
    if (data.team1.id === data.team2.id) {
      setError('root', { message: "Can't compare between identical teams", type: 'validate' });
      return;
    }
    createReport(
      {
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.team_comparison,
          project: project!.id!,
          report_entities: [data.team1.id as number, data.team2.id as number] // TODO: figure out the format
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedTeam1 = watch('team1');
  const selectedTeam2 = watch('team2');
  useEffect(() => {
    if (selectedTeam1 && selectedTeam2) {
      setValue('name', `${selectedTeam1.label} vs ${selectedTeam2.label}`);
    } else {
      setValue('name', '');
    }
  }, [selectedTeam1, selectedTeam2, setValue]);

  return (
    <DialogBase title="New Team Comparison Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form
          className="flex w-full flex-col gap-10"
          id="new-team-comparison-report-form"
          onSubmit={formSubmit(handleSubmit)}
        >
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Team</span>
            <Combobox
              rules={{ required: { value: true, message: 'Team is required.' } }}
              error={errors.team1}
              loading={isFetchingTeams1}
              setQuery={setTeam1Query}
              name="team1"
              control={control}
              label="Team 1"
              placeholder="Start typing: Team name / Team country"
              options={team1Options}
            />
            <Combobox
              rules={{
                required: { value: true, message: 'Team is required.' }
              }}
              error={errors.team2}
              loading={isFetchingTeams2}
              setQuery={setTeam2Query}
              name="team2"
              control={control}
              label="Team 2"
              placeholder="Start typing: Team name / Team country"
              options={team2Options}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-team-comparison-report-form" loading={isCreating}>
          <span>Create Team Comparison Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewTeamComparisonReportDialog;
