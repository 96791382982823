/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Label, Listbox, ListboxButton } from '@headlessui/react';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loading from 'assets/loading.svg?react';
import { memo } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { ValueOption } from 'utils/interfaces';
import ErrorMessage from './ErrorMessage';
import InfoMessage from './InfoMessage';
import ValueOptionsDropdown from './ValueOptionsDropdown';

interface SingleSelectInputProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  options?: ValueOption[];
  searchable?: boolean;
  error?: Merge<FieldError, FieldErrorsImpl<ValueOption>>;
  loading?: boolean;
  info?: string;
}

function SingleSelectInput<T extends FieldValues>({
  label,
  placeholder,
  options,
  error,
  searchable,
  info,
  disabled = false,
  loading = false,
  defaultValue = null as PathValue<T, Path<T>>,
  ...formProps
}: SingleSelectInputProps<T>) {
  const {
    field: { onChange: formOnChange, value: formValue }
  } = useController<T>({ ...formProps, defaultValue });

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const noOptions = !loading && !disabled && options !== undefined && options.length === 0;

  return (
    <div className="flex w-full flex-col gap-2">
      <Listbox
        disabled={disabled || noOptions}
        className={'relative w-full grow'}
        as={'div'}
        value={formValue as ValueOption | null}
        onChange={formOnChange}
        by="id"
      >
        {({ open, value }) => (
          <>
            <Label
              className={twMerge(
                'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                (placeholder || value) && '-translate-y-0 text-xs'
              )}
            >
              {label}
            </Label>
            <ListboxButton
              ref={refs.setReference}
              className="min-h-14 w-full cursor-pointer rounded-md border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-left text-md focus:outline-none focus:ring-0 ui-disabled:bg-gray-50"
            >
              {formValue ? formValue.label : placeholder}
            </ListboxButton>
            {loading ? (
              <Loading
                aria-hidden="true"
                width={20}
                height={20}
                className="pointer-events-none absolute inset-y-4.5 right-3 z-10 animate-spin fill-gray-700"
              />
            ) : (
              <ChevronDown
                aria-hidden="true"
                width={20}
                height={20}
                className="pointer-events-none absolute inset-y-4.5 right-3 z-10 fill-gray-700"
              />
            )}
            {!noOptions && !loading && open && (
              <ValueOptionsDropdown
                floatingStyles={floatingStyles}
                ref={refs.setFloating}
                options={options}
                searchable={searchable}
              />
            )}
          </>
        )}
      </Listbox>
      {error && <ErrorMessage error={error} />}
      {info && <InfoMessage info={info} />}
      {noOptions && <InfoMessage info={`${label} has no options available`} />}
    </div>
  );
}

export default memo(SingleSelectInput) as typeof SingleSelectInput;
