import { useQueryClient } from '@tanstack/react-query';
import { defaultDataTablePageSize, defaultPageIndex } from 'constants/tableDefaults';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';
import { DataTablesSchema, ProjectGetSchema } from 'lib/model';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DummyDataBanner from 'modules/layouts/dataTablesLayout/components/DummyDataBanner';
import { memo, useEffect } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

const DataTablesLayout = memo(function DataTablesLayout() {
  const navigate = useNavigate();
  const { dataTableId } = useParams();
  const [searchParams] = useSearchParams();

  // init values if first render
  if (!searchParams.has('pageSize')) {
    searchParams.set('pageSize', String(defaultDataTablePageSize));
  }
  if (!searchParams.has('pageIndex')) {
    searchParams.set('pageIndex', String(defaultPageIndex));
  }

  const queryClient = useQueryClient();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['dataTables'])!;
  const dataTable = dataTables.objects!.find((dt) => dt.id === dataTableId) ?? dataTables.objects![0];

  const project = queryClient.getQueryData<ProjectGetSchema>(['project'])!;
  const { data: dataSourcesCount } = useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
    query: { queryKey: ['dataSourcesCount'], enabled: !!project.id, staleTime: Infinity }
  });
  const useDummyData = dataSourcesCount?.count === 0 || dataTables.dummy_data;

  useEffect(
    function onUrlChange() {
      navigate(`/data-tables/${dataTable.id}?${searchParams.toString()}`, { replace: true });
    },
    [dataTable, searchParams, navigate]
  );

  return (
    <div className="flex size-full grow flex-col">
      <header className="sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 pb-8 pt-10">
        <span className="text-lg font-semibold text-gray-950">Data Tables</span>
      </header>
      <div className="flex grow flex-col gap-6">
        {useDummyData && <DummyDataBanner />}
        <div className={twJoin('flex grow flex-col rounded-xl', useDummyData && 'bg-brand-800')}>
          {useDummyData && (
            <div className="flex items-center justify-between p-6">
              <span className="text-sm font-semibold text-white">This Data is powered by:</span>
              <div className="flex flex-wrap items-center gap-4">
                <div className="rounded-md bg-white px-3 py-1.5">
                  <DataSourceTitle name="SkillCorner" imageSize="sm" />
                </div>
                <div className="rounded-md bg-white px-3 py-1.5">
                  <DataSourceTitle name="Sports Interactive" imageSize="sm" />
                </div>
                <div className="rounded-md bg-white px-3 py-1.5">
                  <DataSourceTitle name="ApiFootball" imageSize="sm" />
                </div>
                <div className="rounded-md bg-white px-3 py-1.5">
                  <DataSourceTitle name="StatsBomb" imageSize="sm" />
                </div>
                <div className="rounded-md bg-white px-3 py-1.5">
                  <DataSourceTitle name="ClubElo" imageSize="sm" />
                </div>
              </div>
            </div>
          )}
          <Outlet />
        </div>
      </div>
    </div>
  );
});

export default DataTablesLayout;
