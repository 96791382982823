import Time from 'assets/time.svg?react';
import { dayOfMonthOptions, dayOfWeekOptions, sendReportScheduleFrequencyOptions } from 'constants/formOptions';
import { timeOptions } from 'constants/generated';
import Checkbox from 'modules/common/Form/Checkbox';
import Input from 'modules/common/Form/Input';
import MultipleValueInput from 'modules/common/Form/MultipleValueInput';
import SingleSelectInput from 'modules/common/Form/SingleSelectInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';

import { ScheduleReportFormValues } from './interfaces';

interface ScheduleReportFormProps {
  scheduleAction: (data: ScheduleReportFormValues) => void;
}

function ScheduleReportForm({ scheduleAction }: ScheduleReportFormProps) {
  const { watch, control, register, formState, handleSubmit } = useForm<ScheduleReportFormValues>({
    defaultValues: {
      emails: [],
      scheduleFrequency: sendReportScheduleFrequencyOptions[0],
      scheduleTimeOfDay: timeOptions[18],
      scheduleDayOfWeek: dayOfWeekOptions[0],
      scheduleDayOfMonth: dayOfMonthOptions[0],
      isEndDateEnabled: false,
      scheduleEndDate: null
    }
  });
  const scheduleFrequency = watch('scheduleFrequency');
  const isEndDateEnabled = watch('isEndDateEnabled');

  return (
    <form className="flex flex-col gap-10" onSubmit={handleSubmit(scheduleAction)} id="send-report-form">
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-2">
          <Time className="size-6 fill-gray-700" />
          <span className="text-md font-semibold">Schedule</span>
          <span className="text-sm font-semibold">[Note: all times are in UTC]</span>
        </div>
        <SingleSelectInput
          control={control}
          name="scheduleFrequency"
          label="Send"
          options={sendReportScheduleFrequencyOptions}
          info={
            scheduleFrequency.id === 'after_iteration'
              ? `This refers to updates based on the
frequency you selected within Q-ant platform,
limited by your tier plan.`
              : undefined
          }
        />
        {scheduleFrequency?.id === 'daily' && (
          <SingleSelectInput control={control} name="scheduleTimeOfDay" label="At" options={timeOptions} />
        )}
        {scheduleFrequency?.id === 'weekly' && (
          <div className="flex gap-6">
            <SingleSelectInput control={control} name="scheduleDayOfWeek" label="On" options={dayOfWeekOptions} />
            <SingleSelectInput control={control} name="scheduleTimeOfDay" label="At" options={timeOptions} />
          </div>
        )}
        {scheduleFrequency?.id === 'monthly' && (
          <div className="flex gap-6">
            <SingleSelectInput control={control} name="scheduleDayOfMonth" label="On" options={dayOfMonthOptions} />
            <SingleSelectInput control={control} name="scheduleTimeOfDay" label="At" options={timeOptions} />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-6">
        <Checkbox registerReturn={register('isEndDateEnabled')} label="End Date" />
        {isEndDateEnabled && (
          <Input
            type="date"
            registerReturn={register('scheduleEndDate', {
              required: { value: isEndDateEnabled, message: 'End date is required if checked.' }
            })}
            label="Ends on"
            error={formState.errors.scheduleEndDate}
          />
        )}
      </div>
      <div className="flex flex-col gap-6">
        <span className="text-md font-semibold text-gray-900">Send report to email</span>
        <MultipleValueInput
          name="emails"
          initialPlaceholder="Ex: user.name@gmail.com, user.name@yahoo.com"
          label="Enter emails"
          control={control}
          rules={{
            validate: {
              minLength: (values: string[]) => (values && values.length > 0) || 'At least one email is required'
            }
          }}
          error={formState.errors.emails}
        />
      </div>
    </form>
  );
}

export default memo(ScheduleReportForm);
