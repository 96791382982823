/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import getProjectRoleMutator from '../../api/axios';
import type { ProjectRolesSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of possible project roles
 */
export const getProjectRole = (options?: SecondParameter<typeof getProjectRoleMutator>, signal?: AbortSignal) => {
  return getProjectRoleMutator<ProjectRolesSchema>({ url: `/project/role`, method: 'GET', signal }, options);
};

export const getGetProjectRoleQueryKey = () => {
  return [`/project/role`] as const;
};

export const getGetProjectRoleQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectRole>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectRole>>, TError, TData>>;
  request?: SecondParameter<typeof getProjectRoleMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectRoleQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectRole>>> = ({ signal }) =>
    getProjectRole(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectRole>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectRole>>>;
export type GetProjectRoleQueryError = unknown;

/**
 * @summary Returns a list of possible project roles
 */
export const useGetProjectRole = <TData = Awaited<ReturnType<typeof getProjectRole>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectRole>>, TError, TData>>;
  request?: SecondParameter<typeof getProjectRoleMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectRoleQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
