import { QueryClient } from '@tanstack/react-query';
import {
  getGetProjectProjectIdDashboardDatasourcesCountQueryOptions,
  getGetProjectProjectIdDashboardIterationStatsQueryOptions
} from 'lib/dashboard/dashboard';
import { getGetProjectProjectIdDataTableQueryOptions } from 'lib/data-table/data-table';
import { getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions } from 'lib/datasource-configuration/datasource-configuration';
import { getGetDatasourceDatasourceIdQueryOptions, getGetDatasourceQueryOptions } from 'lib/datasource/datasource';
import { ProjectGetSchema } from 'lib/model';
import { getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions } from 'lib/project-configuration/project-configuration';
import { getGetProjectRoleQueryOptions } from 'lib/project-role/project-role';
import { getGetProjectProjectIdQueryOptions } from 'lib/project/project';
import { getGetReportQueryOptions, getGetReportReportIdQueryOptions } from 'lib/report/report';
import { getGetProjectProjectIdSubscriptionQueryOptions } from 'lib/subscription/subscription';
import { getGetUserQueryOptions } from 'lib/user-account/user-account';
import { defer, LoaderFunctionArgs } from 'react-router-dom';

async function getProject(queryClient: QueryClient) {
  let project = queryClient.getQueryData<ProjectGetSchema>(['project']);
  if (!project) {
    const userQueryOptions = getGetUserQueryOptions({
      query: { queryKey: ['user'], staleTime: Infinity }
    });
    const user = await queryClient.ensureQueryData(userQueryOptions);
    const projectId = user.user_projects![0].project;

    const projectQueryOptions = getGetProjectProjectIdQueryOptions(projectId!, {
      query: { queryKey: ['project'], enabled: !!projectId, staleTime: Infinity }
    });
    project = await queryClient.ensureQueryData(projectQueryOptions)!;
  }
  return project;
}

export const baseLoader = (queryClient: QueryClient) => async () => {
  const project = await getProject(queryClient);

  const datasourceCountQueryOptions = getGetProjectProjectIdDashboardDatasourcesCountQueryOptions(project.id!, {
    query: { queryKey: ['dataSourcesCount'], enabled: !!project.id, staleTime: Infinity }
  });
  const datasourceCountPromise = queryClient.ensureQueryData(datasourceCountQueryOptions);

  const dataTablesQueryOptions = getGetProjectProjectIdDataTableQueryOptions(project.id!, {
    query: {
      queryKey: ['dataTables'],
      staleTime: Infinity
    }
  });
  const dataTablesPromise = queryClient.ensureQueryData(dataTablesQueryOptions);

  const subscriptionQueryOptions = getGetProjectProjectIdSubscriptionQueryOptions(
    project.id!,
    {},
    { query: { queryKey: ['activeTier'], staleTime: Infinity } }
  );
  const subscriptionPromise = queryClient.ensureQueryData(subscriptionQueryOptions);

  const projectRolesQueryOptions = getGetProjectRoleQueryOptions({
    query: { queryKey: ['projectRoles'], retry: false, staleTime: Infinity }
  });
  const projectRolesPromise = queryClient.ensureQueryData(projectRolesQueryOptions);

  const initialData = Promise.all([
    datasourceCountPromise,
    dataTablesPromise,
    subscriptionPromise,
    projectRolesPromise
  ]);

  return defer({ initialData: initialData });
};

export const dashboardLoader = (queryClient: QueryClient) => async () => {
  const project = await getProject(queryClient);

  const today = new Date();
  const initialLastDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0];
  const iterationStatsQueryOptions = getGetProjectProjectIdDashboardIterationStatsQueryOptions(
    project.id!,
    {
      last_date: initialLastDate
    },
    {
      query: {
        staleTime: 1000 * 60 * 15 // 15 minutes
      }
    }
  );
  queryClient.ensureQueryData(iterationStatsQueryOptions);

  return null;
};

export const dataSourcesLoader = (queryClient: QueryClient) => async () => {
  const project = await getProject(queryClient);
  const projectConfigurationId = project.project_configuration_latest;

  const dataSourcesQueryOptions = getGetDatasourceQueryOptions(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });
  queryClient.ensureQueryData(dataSourcesQueryOptions);

  const dataSourceConfigurationsQueryOptions =
    getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(
      project!.project_configuration_latest!,
      {
        query: {
          queryKey: ['dataSourceConfigurations'],
          staleTime: Infinity,
          enabled: !!project
        }
      }
    );
  queryClient.ensureQueryData(dataSourceConfigurationsQueryOptions);

  const projectConfigurationQueryOptions = getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
    project.id!,
    projectConfigurationId!,
    { nested: true },
    {
      query: {
        queryKey: ['projectConfiguration'],
        enabled: !!projectConfigurationId,
        staleTime: Infinity,
        refetchOnReconnect: false
      }
    }
  );
  queryClient.ensureQueryData(projectConfigurationQueryOptions);

  return null;
};

export const dataSourceDetailsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs<string>) => {
    const queryOptions = getGetDatasourceDatasourceIdQueryOptions(params.dataSourceId!, {
      query: {
        queryKey: ['dataSourcesById', params.dataSourceId],
        staleTime: Infinity
      }
    });
    return queryClient.ensureQueryData(queryOptions);
  };

export const dataTablesLoader = (queryClient: QueryClient) => async () => {
  // ENDPOINTS CALLED IN BASE LOADER
  // const project = await getProject(queryClient);

  // const queryOptions = getGetProjectProjectIdDataTableQueryOptions(project.id!, {
  //   query: {
  //     queryKey: ['dataTables'],
  //     staleTime: Infinity
  //   }
  // });
  // queryClient.ensureQueryData(queryOptions);
  return null;
};

export const reportsLoader = (queryClient: QueryClient) => async () => {
  const reportQueryOptions = getGetReportQueryOptions({}, { query: { queryKey: ['reports'] } });
  const reports = queryClient.ensureQueryData(reportQueryOptions);
  return reports;
};

export const reportLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs<string>) => {
    const reportQueryOptions = getGetReportReportIdQueryOptions(params.reportId!, {
      query: {
        queryKey: ['reports', params.reportId!]
      }
    });
    const report = queryClient.ensureQueryData(reportQueryOptions);
    return report;
  };
