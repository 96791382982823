import { DatasourceSchema } from 'lib/model';
import { memo } from 'react';
import { getInitials } from 'utils/helpers';
import { EventCardProps } from '../EventCard';

function DataSourcesRemovedEvent({ event }: EventCardProps) {
  return (
    <div className="flex flex-col gap-1 rounded-md bg-red-50 p-3">
      <span className="text-xs font-medium text-gray-500">{event.title}</span>
      <div className="flex flex-col">
        {event.json_content!.datasources?.map((ds: DatasourceSchema) => (
          <div key={ds.id} className="flex items-center gap-2 py-1.5">
            <div className="brand-gradient flex size-5 items-center justify-center rounded-md border border-gray-200">
              <span className="text-tiny font-semibold text-white">{getInitials(ds.name)}</span>
            </div>
            <span className="text-xs font-semibold">{ds.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(DataSourcesRemovedEvent);
