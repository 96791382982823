import { ReportElementTemplateSchema } from 'lib/model';
import MultipleSelectInput from 'modules/common/Form/MultipleSelectInput';
import { memo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MatchHeatMapElementFormValues } from '../interfaces';
import { playerToValueOption } from 'utils/mappings';

interface HeatMapElementFormProps {
  template: ReportElementTemplateSchema;
  heatMapElementForm: UseFormReturn<MatchHeatMapElementFormValues>;
  homeTeam: string;
  awayTeam: string;
}

const MatchHeatMapElementForm = memo(function MatchHeatMapElementForm({
  template,
  heatMapElementForm,
  homeTeam: _home,
  awayTeam: _away
}: HeatMapElementFormProps) {
  // POSITION AND PRESSURE HIDDEN UNTIL API SUPPORT IS ADDED
  // const formState = useFormState(heatMapElementForm);
  return (
    <>
      <div className="flex flex-col gap-6">
        <MultipleSelectInput
          name={'home_players'}
          control={heatMapElementForm.control}
          label={'Home Team Players'}
          placeholder="All"
          options={template.attribute_choices!.home_players?.map(playerToValueOption) || []}
        />
        <MultipleSelectInput
          name={'away_players'}
          control={heatMapElementForm.control}
          label={'Away Team Players'}
          placeholder="All"
          options={template.attribute_choices!.away_players?.map(playerToValueOption) || []}
        />
      </div>
      {/* <ButtonTabs
        name="time_period"
        control={heatMapElementForm.control}
        options={template.attribute_choices!.time_period.map(
          (val: string) =>
            ({
              label: val,
              id: val
            }) as ValueOption
        )}
        rules={{
          required: 'Time period is required!'
        }}
        error={formState.errors.time_period}
      /> */}
      {/* <div className="grid grid-cols-2 gap-x-6 gap-y-3">
        <span className="text-xs font-semibold">{homeTeam}</span>
        <span className="text-xs font-semibold">{awayTeam}</span>
        <div className="grid grid-cols-2 gap-x-3">
          <SimpleSingleSelectInput
            name={'home_phase'}
            control={heatMapElementForm.control}
            options={template.attribute_choices!.home_phase.map(
              (val: string) =>
                ({
                  label: val,
                  id: val
                }) as ValueOption
            )}
            rules={{
              required: 'Home phase is required!'
            }}
            error={formState.errors.home_phase}
          />
          <SimpleSingleSelectInput
            name={'home_pressure'}
            control={heatMapElementForm.control}
            options={template.attribute_choices!.home_pressure.map(
              (val: string) =>
                ({
                  label: val,
                  id: val
                }) as ValueOption
            )}
            rules={{
              required: 'Home pressure is required!'
            }}
            error={formState.errors.home_pressure}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-3">
          <SimpleSingleSelectInput
            name={'away_phase'}
            control={heatMapElementForm.control}
            options={template.attribute_choices!.away_phase.map(
              (val: string) =>
                ({
                  label: val,
                  id: val
                }) as ValueOption
            )}
            rules={{
              required: 'Away phase is required!'
            }}
            error={formState.errors.away_phase}
          />
          <SimpleSingleSelectInput
            name={'away_pressure'}
            control={heatMapElementForm.control}
            options={template.attribute_choices!.away_pressure.map(
              (val: string) =>
                ({
                  label: val,
                  id: val
                }) as ValueOption
            )}
            rules={{
              required: 'Away pressure is required!'
            }}
            error={formState.errors.away_pressure}
          />
        </div>
      </div> */}
    </>
  );
});

export default MatchHeatMapElementForm;
