import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { newProjectConfigDefault } from 'constants/newProjectConfigDefault';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import { getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions } from 'lib/datasource-configuration/datasource-configuration';
import {
  DatasourceConfigurationCreateSchema,
  ProjectConfigurationCreateSchema,
  ProjectConfigurationNestedSchema,
  ProjectConfigurationSchema,
  ProjectGetSchema
} from 'lib/model';
import {
  getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions,
  useGetProjectProjectIdConfigurationProjectConfigurationId,
  usePostProjectProjectIdConfiguration
} from 'lib/project-configuration/project-configuration';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

const ConnectDataSourcesDialog = memo(function ConnectDataSourcesDialog({ open, setOpen }: DialogProps) {
  const navigate = useNavigate();
  const { selectedDataSources, setSelectedDataSources } = useSelectedDataSources();
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project'])!;
  const { data: projectConfiguration, isFetching: isFetchingProjectConfiguration } =
    useGetProjectProjectIdConfigurationProjectConfigurationId<ProjectConfigurationNestedSchema>(
      project.id!,
      project.project_configuration_latest!,
      { nested: true },
      {
        query: {
          queryKey: ['projectConfiguration'],
          staleTime: Infinity
        }
      }
    );
  const { mutate: postProjectConfig } = usePostProjectProjectIdConfiguration();
  const [loading, setLoading] = useState(false);

  function convertSelectedDataSourcesToConfigs() {
    return selectedDataSources.map((x) => {
      return {
        datasource: x.dataSource.id,
        project_configuration_id: projectConfiguration?.id,
        datasource_configuration_competitions: x.state.configuration?.leagues.map((l) => ({
          datasource_competition_id: l.id,
          start_season: x.state.configuration!.min_season!.id
        })),
        datasource_configuration_endpoints: x.dataSource.datasource_endpoints?.map((e) => ({
          datasource_endpoint_id: e.id,
          datasource_configuration_id: undefined
        })),
        datasource_configuration_options: x.dataSource
          .datasource_options!.filter((o) => o.required)
          .map((o) => ({
            datasource_option_id: o.id,
            value: null // TODO: fix this at some point! currently params are stored  on backend when being checked in the previous step
          })),
        frequency: x.state.configuration!.time.id + ' ' + x.state.configuration!.refreshFrequency.id
      } as DatasourceConfigurationCreateSchema;
    });
  }

  function connectDataSources() {
    setLoading(true);
    let newProjectConfig: ProjectConfigurationCreateSchema;
    if (projectConfiguration) {
      const optionValue =
        projectConfiguration.project_configuration_options?.length &&
        projectConfiguration.project_configuration_options[0].value !== 'none'
          ? projectConfiguration.project_configuration_options[0].value!
          : '';
      newProjectConfig = {
        ...projectConfiguration,
        id: undefined,
        project: projectConfiguration.project!,
        datasource_configurations: [
          ...projectConfiguration!.datasource_configurations!.map((x) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { stats, id, ...rest } = x;
            return { ...rest };
          }),
          ...convertSelectedDataSourcesToConfigs()
        ],
        project_configuration_options: [
          {
            project_option_id: '2bf8b9dc-424c-41fd-a06e-78478be73743',
            value:
              optionValue !== ''
                ? optionValue + ', ' + selectedDataSources.map((x) => x.dataSource.name?.toLowerCase()).join(', ')
                : selectedDataSources.map((x) => x.dataSource.name?.toLowerCase()).join(', ')
          }
        ]
      };
    } else {
      newProjectConfig = {
        ...newProjectConfigDefault,
        project: project!.id!,
        datasource_configurations: [...convertSelectedDataSourcesToConfigs()],
        project_configuration_options: [
          {
            project_option_id: '2bf8b9dc-424c-41fd-a06e-78478be73743',
            value: selectedDataSources.map((x) => x.dataSource.name?.toLowerCase()).join(', ')
          }
        ]
      };
    }
    postProjectConfig(
      {
        projectId: newProjectConfig.project!,
        data: newProjectConfig
      },
      {
        onSuccess: async (result: ProjectConfigurationSchema) => {
          queryClient.removeQueries({ queryKey: ['projectConfiguration'] });
          queryClient.removeQueries({ queryKey: ['dataSourceConfigurations'] });
          queryClient.invalidateQueries({ queryKey: ['dataSourcesCount'] });
          await queryClient.invalidateQueries({ queryKey: ['project'], exact: true, refetchType: 'all' });

          // Fetch data source configurations
          await queryClient.prefetchQuery(
            getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(result.id!, {
              query: { queryKey: ['dataSourceConfigurations'], staleTime: Infinity }
            })
          );

          // Start project configuration refetch in the background
          queryClient.prefetchQuery(
            getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
              result.project!,
              result.id!,
              { nested: true },
              {
                query: {
                  queryKey: ['projectConfiguration'],
                  staleTime: Infinity
                }
              }
            )
          );

          setLoading(false);
          setSelectedDataSources([]);
          navigate('/data-sources/connected');
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const data = err.response?.data;
            let errorMessage: string | JSX.Element = '';
            if (data.error) {
              errorMessage = data.error;
            }
            if (data._schema) {
              const errors = data._schema as string[];
              errorMessage = (
                <div>
                  {errors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              );
            }
            toast.error(errorMessage);
          }
          setLoading(false);
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
  }

  return (
    <DialogBase title="Connect Data Sources" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex flex-col gap-3">
          {selectedDataSources.map((ds) => {
            return (
              <div key={ds.dataSource.id} className="rounded-md bg-gray-50 p-3">
                <DataSourceTitle name={ds.dataSource.name!} size="md" image={ds.dataSource?.logo_image_path} />
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-6">
          <p className="flex flex-col text-sm">
            <span>Ready to activate chosen Data Sources?</span>
            <span>
              Keep in mind that data Synchronization may take up to <b>24 hours.</b>
            </span>
          </p>
          <p className="flex flex-col text-sm">
            <span>Important:</span>
            <span className="font-semibold">Once activated, you can't remove them for 30 days.</span>
            <span className="font-semibold">Confirm activation?</span>
          </p>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="xl"
          onClick={connectDataSources}
          loading={loading || isFetchingProjectConfiguration}
        >
          Connect Data Source
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default ConnectDataSourcesDialog;
