/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Combobox as ComboboxBase, ComboboxButton, ComboboxInput, Label } from '@headlessui/react';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loader from 'assets/loading.svg?react';
import { memo, useCallback, useEffect, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { AutocompleteOption } from 'utils/interfaces';
import ErrorMessage from './ErrorMessage';
import ValueBadge from './ValueBadge';
import AutocompleteVirtualOptionsDropdown from './AutocompleteVirtualOptionsDropdown';

interface MultipleSelectComboboxProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  options: AutocompleteOption[];
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  error?: Merge<FieldError, FieldErrorsImpl<AutocompleteOption[]>>;
  loading: boolean;
}

function MultipleSelectAsyncCombobox<T extends FieldValues>({
  options,
  setQuery,
  loading,
  label,
  placeholder,
  error,
  defaultValue = [] as PathValue<T, Path<T>>,
  ...formProps
}: MultipleSelectComboboxProps<T>) {
  const [internalQuery, setInternalQuery] = useState('');
  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController({ ...formProps, defaultValue });

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const handleInputChange = useCallback(function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInternalQuery(event.target.value);
  }, []);

  const handleRemove = useCallback(
    function handleRemove(removedValue: string) {
      formOnChange((formValues as AutocompleteOption[]).filter((x) => x.label !== removedValue));
    },
    [formOnChange, formValues]
  );

  const handleChange = useCallback(
    function handleChange(val: AutocompleteOption[]) {
      formOnChange(val);
    },
    [formOnChange]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setQuery(internalQuery);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [internalQuery, setQuery]);

  return (
    <div className="flex w-full flex-col gap-2">
      <ComboboxBase
        onChange={handleChange}
        as="div"
        className="relative flex w-full grow flex-col rounded-lg border border-gray-300 bg-white"
        value={(formValues ?? []) as AutocompleteOption[]}
        by="id"
        multiple
        virtual={{
          // @ts-expect-error - incorrect expected type
          options: options
        }}
        ref={refs.setReference}
      >
        {({ open }) => (
          <>
            <Label
              className={twMerge(
                'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                (Boolean(placeholder) || formValues) && '-translate-y-0 text-xs'
              )}
            >
              {label}
            </Label>
            <ComboboxInput
              className="peer h-14 w-full appearance-none rounded-lg border-0 bg-white px-3 pb-1.5 pt-6 text-md placeholder:text-gray-400 focus:outline-none focus:ring-0"
              onChange={handleInputChange}
              placeholder={!!internalQuery ? internalQuery : placeholder}
              value={internalQuery}
            />
            {loading ? (
              <Loader
                aria-hidden="true"
                className="pointer-events-none absolute right-3 top-5 z-10 size-5 animate-spin fill-gray-700"
              />
            ) : (
              <ComboboxButton className="absolute right-3 top-5 z-10">
                <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
              </ComboboxButton>
            )}
            {formValues && formValues.length > 0 && (
              <div className="flex flex-wrap items-center gap-2 p-3 pt-0">
                {formValues.map((option: AutocompleteOption) => (
                  <ValueBadge value={option.label} key={option.id} onRemove={handleRemove} />
                ))}
              </div>
            )}
            {open && !loading && options.length > 0 && (
              <AutocompleteVirtualOptionsDropdown floatingStyles={floatingStyles} ref={refs.setFloating} />
            )}
          </>
        )}
      </ComboboxBase>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(MultipleSelectAsyncCombobox) as typeof MultipleSelectAsyncCombobox;
