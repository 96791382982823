import { PostDatasourceDatasourceIdCheckCredentialsBody } from 'lib/model';
import Button from 'modules/common/Button';
import Input from 'modules/common/Form/Input';
import PasswordInput from 'modules/common/Form/PasswordInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

interface EmailAuthFormProps {
  dataSource: string;
  website: string;
  signIn: (data: PostDatasourceDatasourceIdCheckCredentialsBody) => void;
  authError: string;
  isPending: boolean;
}

interface EmailAuthFormValues {
  email: string;
  password: string;
}

const EmailAuthForm = memo(function EmailAuthForm({
  dataSource,
  website,
  signIn,
  authError,
  isPending
}: EmailAuthFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EmailAuthFormValues>();

  function onSubmit(data: EmailAuthFormValues) {
    signIn({ username: data.email, password: data.password });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <span className="text-sm font-medium">
        In order to connect to this Data Source you need to <strong>enter credentials</strong>.
      </span>
      <div className="flex flex-col gap-6">
        <Input
          registerReturn={register('email', {
            required: 'Email is required'
          })}
          error={errors.email}
          label="Username"
        />
        <PasswordInput
          registerReturn={register('password', { required: 'Password is required' })}
          label="Password"
          error={errors.password}
        />
      </div>
      <span className="text-sm font-medium">
        If you dont have an account.&nbsp;
        <Link to={website} target="_blank" className="text-brand-800 underline" rel="noreferrer">
          Sign up through {dataSource}
        </Link>
      </span>
      <div className="mt-4 flex flex-col gap-2">
        {authError.length > 0 && <span className="pb-2 text-sm font-medium text-red-600">{authError}</span>}
        <Button isSubmitButton={true} loading={isPending}>
          Authenticate
        </Button>
      </div>
    </form>
  );
});

export default EmailAuthForm;
