import { getOpposingTeams } from 'modules/reports/helpers';
import { memo, useCallback, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { AttributeValues } from '../../../elements/interfaces';
import {
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../../reportCards/interfaces';
import {
  attributeValuesCompetition,
  attributeValuesMatchDetailed,
  attributeValuesOption,
  attributeValuesPlayersHeatMap
} from '../helpers';
import {
  ComparisonHeatMapElementFormValues,
  ElementFormProps,
  HeatMapElementFormValues,
  MatchHeatMapElementFormValues,
  SingleHeatMapElementFormValues
} from '../interfaces';
import ComparisonHeatMapElementForm from './ComparisonHeatMapElementForm';
import MatchHeatMapElementForm from './MatchHeatMapElementForm';
import SingleHeatMapElementForm from './SingleHeatMapElementForm';

const HeatMapElementForm = memo(function HeatMapElementForm({
  report,
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<HeatMapElementFormValues> = {};

  if (element?.attribute_values) {
    const attributes = element?.attribute_values as AttributeValues<HeatMapElementFormValues>;
    for (const [key, values] of Object.entries(attributes)) {
      switch (key) {
        case 'home_players':
        case 'away_players':
        case 'players':
        case 'players_comparison':
          defaultValues[key] = attributeValuesPlayersHeatMap(template.attribute_choices![key], values as number[]);
          break;
        case 'has_comparison':
          defaultValues[key] = values[0] as boolean;
          break;
        case 'home_phase':
        case 'away_phase':
        case 'home_pressure':
        case 'away_pressure':
        case 'seasons':
        case 'seasons_comparison':
          defaultValues[key] = attributeValuesOption(values as string[]);
          break;
        case 'competitions':
        case 'competitions_comparison':
          defaultValues[key] = attributeValuesCompetition(template.attribute_choices![key], values as number[]);
          break;
        case 'matches':
        case 'matches_comparison':
          defaultValues[key] = attributeValuesMatchDetailed(template.attribute_choices![key], values as number[]);
          break;
      }
    }

    // if (values.time_period) {
    //   defaultValues.time_period = attributeValuesOption(values.time_period as string[]);
    // }
  }
  const heatMapElementForm = useForm<HeatMapElementFormValues>({
    defaultValues
  });

  const addElement = useCallback(
    function addElement(data: HeatMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );
  const [homeTeam, awayTeam] = getOpposingTeams(report)!;

  const heatMapForm = useMemo(() => {
    if (report.report_type === 'match') {
      return (
        <MatchHeatMapElementForm
          template={template}
          heatMapElementForm={heatMapElementForm as UseFormReturn<MatchHeatMapElementFormValues>}
          homeTeam={homeTeam.team_name}
          awayTeam={awayTeam!.team_name}
        />
      );
    } else if (report.report_type === 'player' || report.report_type === 'team') {
      const subject =
        report.report_type === 'player'
          ? (report.general_data as PlayerGeneralData).player_name
          : (report.general_data as TeamGeneralData).team_name;
      return (
        <SingleHeatMapElementForm
          template={template}
          heatMapElementForm={heatMapElementForm as UseFormReturn<SingleHeatMapElementFormValues>}
          subject={subject}
        />
      );
    } else if (report.report_type === 'player_comparison' || report.report_type === 'team_comparison') {
      const subject1 =
        report.report_type === 'player_comparison'
          ? (report.general_data as PlayerComparisonGeneralData).player1.player_name
          : (report.general_data as TeamComparisonGeneralData).team1.team_name;
      const subject2 =
        report.report_type === 'player_comparison'
          ? (report.general_data as PlayerComparisonGeneralData).player2.player_name
          : (report.general_data as TeamComparisonGeneralData).team2.team_name;
      return (
        <ComparisonHeatMapElementForm
          template={template}
          heatMapElementForm={heatMapElementForm as UseFormReturn<ComparisonHeatMapElementFormValues>}
          subject1={subject1}
          subject2={subject2}
        />
      );
    }
  }, [awayTeam, heatMapElementForm, homeTeam.team_name, report.general_data, report.report_type, template]);

  return (
    <form
      className="flex w-full flex-col gap-10"
      id="new-element-form"
      onSubmit={heatMapElementForm.handleSubmit(addElement)}
    >
      {heatMapForm}
    </form>
  );
});

export default HeatMapElementForm;
