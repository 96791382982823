/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Combobox as ComboboxBase, ComboboxButton, ComboboxInput, Label } from '@headlessui/react';
import { useDebounce } from '@uidotdev/usehooks';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loader from 'assets/loading.svg?react';
import { memo, useCallback, useState } from 'react';
import { FieldError, FieldErrorsImpl, FieldValues, Merge, useController, UseControllerProps } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { AutocompleteOption } from 'utils/interfaces';
import AutocompleteVirtualOptionsDropdown from './AutocompleteVirtualOptionsDropdown';
import ErrorMessage from './ErrorMessage';
import ValueBadge from './ValueBadge';

interface MultipleSelectComboboxProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  options: AutocompleteOption[];
  error?: Merge<FieldError, FieldErrorsImpl<AutocompleteOption>>;
  loading: boolean;
}

function MultipleSelectCombobox<T extends FieldValues>({
  options,
  loading,
  label,
  placeholder,
  error,
  ...formProps
}: MultipleSelectComboboxProps<T>) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController(formProps);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const handleInputChange = useCallback(function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }, []);

  const handleRemove = useCallback(
    function handleRemove(removedValue: string) {
      formOnChange((formValues as AutocompleteOption[]).filter((x) => x.label !== removedValue));
    },
    [formOnChange, formValues]
  );

  const handleChange = useCallback(
    function handleChange(val: AutocompleteOption[]) {
      formOnChange(val);
    },
    [formOnChange]
  );

  const filteredOptions = !debouncedQuery
    ? options
    : options.filter((option) => option.label.toLowerCase().includes(debouncedQuery.toLowerCase()));

  return (
    <div className="flex w-full flex-col gap-2">
      <ComboboxBase
        as="div"
        className="relative flex w-full grow flex-col rounded-lg border border-gray-300 bg-white"
        by="id"
        value={(formValues ?? []) as AutocompleteOption[]}
        onChange={handleChange}
        multiple
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        virtual={{ options: filteredOptions }}
        immediate
      >
        {({ open }) => (
          <>
            <Label
              className={twMerge(
                'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                (Boolean(placeholder) || formValues) && '-translate-y-0 text-xs'
              )}
            >
              {label}
            </Label>
            <ComboboxInput
              className="peer h-14 w-full appearance-none rounded-lg border-0 bg-white px-3 pb-1.5 pt-6 text-md placeholder:text-gray-400 focus:outline-none focus:ring-0"
              onChange={handleInputChange}
              placeholder={!!query ? query : placeholder}
              value={query}
              ref={refs.setReference}
            />
            {loading ? (
              <Loader
                aria-hidden="true"
                className="pointer-events-none absolute right-3 top-5 z-10 size-5 animate-spin fill-gray-700"
              />
            ) : (
              <ComboboxButton className="absolute right-3 top-5 z-10">
                <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
              </ComboboxButton>
            )}
            {formValues && formValues.length > 0 && (
              <div className="flex flex-wrap items-center gap-2 p-3 pt-0">
                {formValues.map((option: AutocompleteOption) => (
                  <ValueBadge value={option.label} key={option.id} onRemove={handleRemove} />
                ))}
              </div>
            )}
            {open && !loading && options.length > 0 && (
              <AutocompleteVirtualOptionsDropdown floatingStyles={floatingStyles} ref={refs.setFloating} />
            )}
          </>
        )}
      </ComboboxBase>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(MultipleSelectCombobox) as typeof MultipleSelectCombobox;
