import CheckCircle from 'assets/check-circle.svg?react';
import Progress from 'assets/progress.svg?react';
import Unknown from 'assets/unknown.svg?react';
import Unlink from 'assets/unlink.svg?react';
import Warning from 'assets/warning.svg?react';
import SyncStatusEnum from 'constants/enums/syncStatus';
import { memo } from 'react';

interface SyncStatusBadgeProps {
  status: string;
  inactive: boolean;
}

const DataSourceSyncStatusBadge = memo(function SyncStatusBadge({ status, inactive }: SyncStatusBadgeProps) {
  if (inactive) {
    return (
      <div className="flex w-fit items-center gap-1 rounded-badge border border-gray-300 bg-gray-50 px-3 py-2">
        <Unlink width={14} height={14} className="fill-gray-500" />
        <span className="text-tiny font-semibold text-gray-500">INACTIVE</span>
      </div>
    );
  } else if (status === SyncStatusEnum.FAILED) {
    return (
      <div className="flex w-fit items-center gap-1 rounded-badge border border-red-200 bg-red-50 px-3 py-2">
        <Warning width={14} height={14} className="fill-red-600" />
        <span className="text-tiny font-semibold text-red-600">SYNC FAILED</span>
      </div>
    );
  } else if (status === SyncStatusEnum.SUCCESSFUL) {
    return (
      <div className="flex w-fit items-center gap-1 rounded-badge border border-green-400 bg-green-50 px-3 py-2">
        <CheckCircle width={14} height={14} className="fill-green-600" />
        <span className="text-tiny font-semibold text-green-600">SYNCED</span>
      </div>
    );
  } else if (status === SyncStatusEnum.IN_PROGRESS) {
    return (
      <div className="flex w-fit items-center gap-1 rounded-badge border border-orange-400 bg-orange-50 px-3 py-2">
        <Progress width={14} height={14} className="fill-orange-600" />
        <span className="text-tiny font-semibold text-orange-600">SYNC IN PROGRESS</span>
      </div>
    );
  } else if (status === SyncStatusEnum.UNKNOWN) {
    return (
      <div className="flex w-fit items-center gap-1 rounded-badge border border-gray-300 bg-gray-50 px-3 py-2">
        <Unknown width={14} height={14} className="fill-gray-500" />
        <span className="text-tiny font-semibold text-gray-500">UNKNOWN</span>
      </div>
    );
  } else {
    return null;
  }
});

export default DataSourceSyncStatusBadge;
