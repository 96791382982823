import { useQueryClient } from '@tanstack/react-query';
import Previous from 'assets/chevron-left.svg?react';
import Next from 'assets/chevron-right.svg?react';
import { useGetProjectProjectIdDashboardIterationStats } from 'lib/dashboard/dashboard';
import { ProjectSchema } from 'lib/model';
import { memo, useCallback, useMemo, useState } from 'react';
import IntegrationChart from './IntegrationChart';
import IntegrationDataSourceLogo from './IntegrationDataSourceLogo';
import { IntegrationChartDataSource, IntegrationChartPoint } from './interfaces';
import List from 'assets/list-framed.svg?react';

const today = new Date();
const initialLastDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0];

function IntegrationChartCard() {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const [lastDate, setLastDate] = useState<string>(initialLastDate);

  const { data: iterationData, isPending: isIterationDataPending } = useGetProjectProjectIdDashboardIterationStats(
    project.id!,
    {
      last_date: lastDate
    },
    {
      query: {
        staleTime: 1000 * 60 * 15 // 15 minutes
      }
    }
  );

  const chartPoints = useMemo(() => {
    const _chartPoints: IntegrationChartPoint[] = [];
    if (!iterationData?.objects) {
      return _chartPoints;
    }
    for (const [key, value] of Object.entries(iterationData.objects)) {
      _chartPoints.push({
        x_value: new Date(key),
        y_value: value.map((data) => data.new_or_updated_entries!).reduce((acc, curr) => acc + curr, 0),
        datasources: value.reduce((acc: IntegrationChartDataSource[], curr) => {
          acc.push({
            id: curr.datasource_id!,
            name: curr.datasource_name!,
            status: curr.ingest_status!,
            value: curr.new_or_updated_entries!
          });
          return acc;
        }, [])
      });
    }
    return _chartPoints;
  }, [iterationData]);

  const dataSources = useMemo(() => {
    const dataSourcesObj = chartPoints.reduce((acc: Record<string, IntegrationChartDataSource>, curr) => {
      for (const dataSource of curr.datasources) {
        if (!acc[dataSource.id]) {
          acc[dataSource.id] = dataSource;
        }
      }
      return acc;
    }, {});
    return Object.values(dataSourcesObj);
  }, [chartPoints]);

  const scale = useMemo(() => {
    let _scale: 'day' | 'week' | 'month' | 'year' = 'day';
    if (iterationData?.iteration_frequency === 'daily') {
      _scale = 'day';
    } else if (iterationData?.iteration_frequency === 'weekly') {
      _scale = 'week';
    } else if (iterationData?.iteration_frequency === 'monthly') {
      _scale = 'month';
    } else if (iterationData?.iteration_frequency === 'yearly') {
      _scale = 'year';
    }
    return _scale;
  }, [iterationData?.iteration_frequency]);

  const onNext = useCallback(
    function onNext() {
      const endDay = new Date(
        Object.keys(iterationData!.objects!).sort()[Object.keys(iterationData!.objects!).length - 1]
      );
      if (scale === 'day') {
        endDay.setDate(endDay.getDate() + Object.keys(iterationData!.objects!).length * 1);
      } else if (scale === 'week') {
        endDay.setDate(endDay.getDate() + Object.keys(iterationData!.objects!).length * 7);
      } else if (scale === 'month') {
        endDay.setMonth(endDay.getMonth() + Object.keys(iterationData!.objects!).length * 1);
      } else if (scale === 'year') {
        endDay.setFullYear(endDay.getFullYear() + Object.keys(iterationData!.objects!).length * 1);
      }
      setLastDate(new Date(endDay.getTime() - endDay.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    },
    [iterationData, scale]
  );

  const onPrevious = useCallback(
    function onPrevious() {
      const startDate = new Date(Object.keys(iterationData!.objects!).sort()[0]);
      startDate.setDate(startDate.getDate() - 1);
      setLastDate(new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    },
    [iterationData]
  );

  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-3">
            <span className="text-xs font-semibold">Data Sources:</span>
            <div className="flex gap-1">
              {dataSources.map((ds) => (
                <IntegrationDataSourceLogo key={ds.id} name={ds.name} />
              ))}
            </div>
          </div>
          <div className="flex gap-2">
            <button className="group flex items-center justify-center disabled:cursor-not-allowed">
              <Previous
                onClick={onPrevious}
                className="size-6 rounded-md border border-gray-400 fill-gray-700 group-disabled:fill-gray-400"
              />
            </button>
            <button
              disabled={lastDate === initialLastDate}
              className="group flex items-center justify-center disabled:cursor-not-allowed"
            >
              <Next
                onClick={onNext}
                className="size-6 rounded-md border border-gray-400 fill-gray-700 group-disabled:fill-gray-400"
              />
            </button>
          </div>
        </div>
        <div className="flex flex-wrap-reverse justify-between gap-6">
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <div className="size-3 shrink-0 rounded-full bg-green-500" />
              <span className="text-xs font-semibold">Synced</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="size-3 shrink-0 rounded-full bg-orange-400" />
              <span className="text-xs font-semibold">Sync in progress</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="size-3 shrink-0 rounded-full bg-red-500" />
              <span className="text-xs font-semibold">Sync failed</span>
            </div>
          </div>
          <div className="flex gap-2">
            <span className="text-xs font-medium">Refresh Frequency:</span>
            <span className="text-xs font-semibold">{iterationData?.iteration_frequency}</span>
          </div>
        </div>
      </div>
      {isIterationDataPending ? (
        <div className="h-[200px] w-full animate-pulse rounded-xl bg-gray-50" />
      ) : (
        <>
          {chartPoints.length > 0 ? (
            <IntegrationChart points={chartPoints} scale={scale} />
          ) : (
            <div className="flex flex-col items-center gap-3">
              <List className="size-16 fill-gray-300" />
              <span className="text-sm font-medium text-gray-500">No data.</span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default memo(IntegrationChartCard);
