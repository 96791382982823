import { memo } from 'react';
import ComparisonElementForm from './ComparisonElementForm';
import DynamicElementForm from './DynamicElementForm';
import EventMapForm from './EventMapForm';
import HeatMapElementForm from './HeatMapElementForm/HeatMapElementForm';
import LineChartElementForm from './LineChartElementForm';
import PositionMapElementForm from './PositionMapElementForm';
import RadarChartElementForm from './RadarChartElementForm';
import ScatterChartElementForm from './ScatterChartElementForm';
import TableElementForm from './TableElementForm';
import VersusElementForm from './VersusElementForm';
import { ElementFormProps } from './interfaces';

const ElementForm = memo(function ElementForm(props: ElementFormProps) {
  if (!props.template?.attribute_choices) {
    return null;
  } else if (props.template.name === 'heatmap') {
    return <HeatMapElementForm {...props} />;
  } else if (props.template.name === 'avg-positions') {
    return <PositionMapElementForm {...props} />;
  } else if (props.template.name === 'radar-chart') {
    return <RadarChartElementForm {...props} />;
  } else if (props.template.name === 'table') {
    return <TableElementForm {...props} />;
  } else if (props.template.name === 'line-chart') {
    return <LineChartElementForm {...props} />;
  } else if (props.template.name === 'comparison') {
    return <ComparisonElementForm {...props} />;
  } else if (props.template.name === 'scatter-chart') {
    return <ScatterChartElementForm {...props} />;
  } else if (props.template.name === 'versus') {
    return <VersusElementForm {...props} />;
  } else if (props.template.name === 'event-map') {
    return <EventMapForm {...props} />;
  } else {
    return <DynamicElementForm {...props} />;
  }
});

export default ElementForm;
